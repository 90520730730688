import { motion, MotionProps } from "framer-motion";
import { Button } from "../../ui/button";
import { AlignLeft, ChevronRight, Settings } from "lucide-react";
import { cn } from "../../../lib/utils";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useGroupsWithSections } from "../../groups/manager/use-groups-with-sections";
import { Group } from "../../../lib/api-schema/group";
import { minutesToMilliseconds } from "date-fns";
import {
  createContext,
  FC,
  type PropsWithChildren,
  ReactNode,
  useContext,
} from "react";
import { useMemo } from "use-memo-one";
import { ScopeProvider } from "jotai-scope";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../shared-ui/frontend/dropdown-menu";
import { PropsWithCn } from "../../../shared-ui/frontend/cn";

const SelectedGroupContext = createContext<{
  group: Group | undefined;
  onSelect: (group: Group) => void;
}>({
  group: undefined,
  onSelect: () => {
    throw new Error("not provided in component tree");
  },
});

function useSelectedGroup() {
  return useContext(SelectedGroupContext);
}

export function SelectedGroupContextProvider({
  selectedGroup,
  onSelect,
  children,
}: PropsWithChildren<{
  selectedGroup: Group | undefined;
  onSelect: (group: Group) => void;
}>) {
  return (
    <SelectedGroupContext.Provider
      value={useMemo(
        () => ({
          group: selectedGroup,
          onSelect,
        }),
        [selectedGroup, onSelect]
      )}
    >
      {children}
    </SelectedGroupContext.Provider>
  );
}

export const globalGroupsSidebarWidth = "20dvw";

export const globalGroupsSidebarOpenAtom = atom(false);

export type GroupButtonLabelComponent = FC<
  {
    sidebarOpen: boolean;
    group: Group;
    mySectionClosed: boolean;
    selectedGroup: Group | undefined;
  } & PropsWithCn
>;
export const GroupButtonLabelComponentContext = createContext<
  GroupButtonLabelComponent | undefined
>(undefined);

export function GenericGlobalSidebarWithGroups({
  children,
  labelComponent,
}: {
  children: (open: boolean, children: ReactNode) => ReactNode;
  labelComponent?: GroupButtonLabelComponent;
}) {
  const open = useAtomValue(globalGroupsSidebarOpenAtom);

  const variants = {
    open: {
      width: globalGroupsSidebarWidth,
    },
    closed: {
      width: "0",
    },
  } as const satisfies Required<MotionProps["variants"]>;

  return (
    <GroupButtonLabelComponentContext.Provider value={labelComponent}>
      <motion.div
        className={cn(
          "bg-[#2C2C2C] dark text-xslate-11 sticky top-0 overflow-y-auto min-h-[calc(100vh-3rem)] max-h-screen shrink-0",
          open && "p-2"
        )}
        animate={(open ? "open" : "closed") satisfies keyof typeof variants}
        variants={variants}
      >
        {children(open, <SidebarStuff />)}
      </motion.div>
    </GroupButtonLabelComponentContext.Provider>
  );
}

function SidebarStuff() {
  const sidebarOpen = useAtomValue(globalGroupsSidebarOpenAtom);
  const groupsAndSections = useGroupsWithSections({
    enabled: sidebarOpen,
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  });

  if (!groupsAndSections) return;

  return (
    <div className={cn("p-3 flex flex-col gap-4", !sidebarOpen && "hidden")}>
      <div className="flex flex-col">
        {groupsAndSections.remainingGroups.map((g) => {
          return <GroupButton group={g} key={g._id} />;
        })}
      </div>

      {groupsAndSections.sectionsWithGroups.map((section) => {
        return (
          <ScopeProvider key={section.section._id} atoms={[sectionClosedAtom]}>
            <SectionCollapse section={section} />
          </ScopeProvider>
        );
      })}
    </div>
  );
}

const sectionClosedAtom = atom(false);

const transitionForSectionCollapse: MotionProps["transition"] = {
  ease: "easeInOut",
  duration: 0.15, // 150ms
};

function SectionCollapse({
  section,
}: Readonly<{
  section: NonNullable<
    ReturnType<typeof useGroupsWithSections>
  >["sectionsWithGroups"][number];
}>) {
  const [closed, setClosed] = useAtom(sectionClosedAtom);

  const variants = {
    open: {
      height: "auto",
    },
    closed: {
      height: "0",
      overflowY: "hidden",
    },
  } as const satisfies Required<MotionProps["variants"]>;

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 mb-2">
        <span className="font-medium text-sm">{section.section.name}</span>
        <Button
          variant={"ghost"}
          size={"icon-xs"}
          onClick={() => setClosed((x) => !x)}
        >
          <ChevronRight
            className={cn(
              "size-3 transition-transform",
              !closed && "rotate-90"
            )}
          />
        </Button>
      </div>
      <motion.div
        transition={transitionForSectionCollapse}
        className="flex flex-col"
        variants={variants}
        animate={(closed ? "closed" : "open") satisfies keyof typeof variants}
      >
        {section.groups.map((x) => {
          return <GroupButton key={x._id} group={x} />;
        })}
      </motion.div>
    </div>
  );
}

function GroupButton({ group: g }: { group: Group }) {
  const sidebarOpen = useAtomValue(globalGroupsSidebarOpenAtom);

  const mySectionIsClosed = useAtomValue(sectionClosedAtom);

  const { group: selectedGroup, onSelect } = useSelectedGroup();

  const isSelected = selectedGroup?._id === g._id;

  const Label = useContext(GroupButtonLabelComponentContext);

  return (
    <button
      onClick={() => onSelect(g)}
      disabled={isSelected}
      className={cn(
        "flex justify-between rounded-lg grow py-1.5 px-3 text-left font-light break-all transition-all text-sm select-none items-center",
        isSelected
          ? "text-amber-500 font-normal bg-xslate-6"
          : "hover:text-amber-400 hover:pl-4 active:scale-95"
      )}
    >
      <span>{g.name}</span>
      {Label && (
        <Label
          className={cn(
            "animate-in slide-in-from-top-2 spin-in-2 select-none",
            isSelected && "text-amber-500 border-amber-500"
          )}
          group={g}
          mySectionClosed={mySectionIsClosed}
          selectedGroup={selectedGroup}
          sidebarOpen={sidebarOpen}
        />
      )}
    </button>
  );
}

export function ToggleGlobalSidebarButton() {
  const setOpen = useSetAtom(globalGroupsSidebarOpenAtom);
  return (
    <Button size={"xs"} variant={"ghost"} onClick={() => setOpen((x) => !x)}>
      <AlignLeft className="size-4" />
    </Button>
  );
}

export function SettingsDropdown({ children }: PropsWithChildren) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={"ghost"} size="sm">
          <Settings className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 dark">
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
