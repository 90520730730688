import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../shared-ui/frontend/cn";
import DateInput from "./DateInput";
import type { DateFormat, EditableRangeProps } from "./types";
import { FontSizes } from "./styles";
import { DateTime } from "luxon";

export default function EditableDateTimeRange({
  start,
  end,
  className,
  options,
  onChangeHandler,
  close,
  onBlur,
}: EditableRangeProps) {
  const dtFormat: DateFormat = options.showTime
    ? "yyyy/MM/dd HH:mm"
    : "yyyy/MM/dd";

  const endInputRef = useRef<HTMLInputElement>(null);
  const [startStr] = useState(DateTime.fromMillis(start).toFormat(dtFormat));
  const [endStr] = useState(DateTime.fromMillis(end).toFormat(dtFormat));

  // focus on input on init
  useEffect(() => {
    endInputRef.current?.focus();
  }, []);

  return (
    <div
      onBlur={
        onBlur &&
        ((e) => {
          const focusMovedOutsideOfMeAndChildren = !e.currentTarget.contains(
            e.relatedTarget
          );

          focusMovedOutsideOfMeAndChildren && onBlur();
        })
      }
      className={cn(
        className,
        "relative flex flex-row items-center justify-evenly"
      )}
      id="editable-date-selector"
      key="dt-range"
    >
      <DateInput
        className="px-3"
        date={startStr}
        showTime={options.showTime}
        onChange={(d) => {
          onChangeHandler({
            start: new Date(d).getTime(),
            end,
          });
        }}
        onSubmit={() => close?.()}
        // perhaps a tad hacky, but if the user clicks the other input we want to wait for that to get set to true
        // so we don't inadvertently close the date selector
      />
      <span
        id="separator"
        className={cn(
          "px-1 bg-transparent absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 select-none"
        )}
        style={FontSizes[options.fontSize]}
      >
        &mdash;
      </span>
      <DateInput
        className="px-3"
        date={endStr}
        showTime={options.showTime}
        onChange={(d) => {
          onChangeHandler({
            start,
            end: new Date(d).getTime(),
          });
        }}
        onSubmit={() => close?.()}
        ref={endInputRef}
      />
    </div>
  );
}
