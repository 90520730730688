import { type SetURLSearchParams, useSearchParams } from "react-router-dom";
import { useGroupsQuery } from "../../hooks/tanstack-query";
import DateSelectorsGroupSelect from "../dateSelector/DateSelectorsGroupSelect";
import { useSelectedGroup } from "./use-selected-group";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import { useMemo } from "use-memo-one";

export function GroupSelect() {
  const selectedGroup = useSelectedGroup();
  const groupsQuery = useGroupsQuery();
  const [, setSearchParams] = useSearchParams();

  const onSelectGroup = useOnSelectGroupOnDri();

  const groups = groupsQuery.data;

  if (!groups) return;

  return (
    <DateSelectorsGroupSelect
      action={(gid) => onSelectGroup?.(gid, setSearchParams)}
      options={groups.map((g) => ({
        value: g._id,
        label: g.name,
      }))}
      value={selectedGroup?._id}
    />
  );
}

export function useOnSelectGroupOnDri():
  | ((gid: string, setSp: SetURLSearchParams) => void)
  | undefined {
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;

  const onSelectGroup = useMemo(() => {
    if (!groups) return undefined;

    return (newGroupId: string, setSp: SetURLSearchParams) => {
      const g = groups.find((x) => x._id === newGroupId);
      if (!g) return;
      setSp((curr) => {
        const copy = new URLSearchParams(curr);
        copy.delete(DRAParamsMap.v);
        copy.delete(DRAParamsMap.dv);
        copy.set(DRAParamsMap.g, g.short_id.toString());

        return copy;
      });
    };
  }, [groups]);

  return onSelectGroup;
}
