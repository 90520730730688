import { useGetUseTimeseriesChartsTooltipStore } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import * as T from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/global-timeseries-tooltip";
import { usePlantTimeFormatter } from "../../hooks/use-plant-time-formatter";
import {
  useSlopeQuery,
  useVariablesArrayQuery,
} from "../../hooks/tanstack-query";
import { FaArrowUp } from "react-icons/fa";
import { cn, iife } from "../../lib/utils";
import { DateTime } from "luxon";
import { useOperatingModesQuery } from "../om/manager/queries";
import { useMemo, useMemoOne } from "use-memo-one";
import { TimeseriesForBv } from "../../shared-ui/time-series-2/types";
import { isVariableVariant } from "../../shared-ui/time-series-2/draw/draw";

function GlobalTooltip() {
  const useStore = useGetUseTimeseriesChartsTooltipStore();

  if (!useStore)
    throw new Error(
      "useStore is undefined. Only render this component inside a TimeseriesChartTooltipStoreProvider."
    );

  const tooltipData = useStore((s) => s.hoverData);

  const plantFormat = usePlantTimeFormatter("dd-LLL-yyyy hh:mm a");

  const variables = useVariablesArrayQuery().data;

  const tooltipIsForAVariable = tooltipData
    ? isVariableVariant(tooltipData)
    : false;

  const variableId = iife(() => {
    if (!tooltipData) return undefined;
    if (!isVariableVariant(tooltipData)) return undefined;
    return tooltipData.bv.slice(24);
  });

  const variableObj = useMemoOne(() => {
    if (!variables) return;
    if (!variableId) return;

    return variables.find((x) => x._id === variableId);
  }, [variables, variableId]);

  return (
    <T.Container uom={variableObj?.units_of_measurement ?? undefined}>
      {(tooltipData) => {
        return (
          <>
            <T.Label>Time</T.Label>
            <span className="col-auto whitespace-nowrap">
              {plantFormat(tooltipData.t)}
            </span>

            {process.env.NODE_ENV === "development" && (
              <>
                <T.Label>ISO</T.Label>
                <T.Value>{new Date(tooltipData.t).toISOString()}</T.Value>
              </>
            )}
            {tooltipIsForAVariable && tooltipData.shouldDisplayTagId && (
              <>
                <T.Label>Tag ID</T.Label>
                {variableObj ? (
                  <T.Value>
                    {variableObj.trimmedName} | {variableObj.description}
                  </T.Value>
                ) : (
                  <T.ValueLoader />
                )}
              </>
            )}
            {tooltipData.slopingTrendId && (
              <SlopingTrendsTooltipStuff _id={tooltipData.slopingTrendId} />
            )}
            {tooltipData.stageId && <Mode mode={tooltipData.stageId} />}
          </>
        );
      }}
    </T.Container>
  );
}

function Mode({ mode }: { mode: TimeseriesForBv["stages"][number]["_id"] }) {
  const useStore = useGetUseTimeseriesChartsTooltipStore();

  if (!useStore) throw new Error("Should not render ");

  const tooltipData = useStore((s) => s.hoverData);

  const variableId = iife(() => {
    if (!tooltipData) return undefined;
    if (!isVariableVariant(tooltipData)) return undefined;
    return tooltipData.bv.slice(24);
  });

  const omQuery = useOperatingModesQuery({ refetchOnMount: false });
  const modes = omQuery.data;

  const hasConfiguredOperatingModes = useMemo(() => {
    if (!variableId) return false;
    if (!modes) return false;

    return modes.some((mode) =>
      mode.bindingVariableIdsSet.includes(variableId)
    );
  }, [modes, variableId]);

  const canShowRemainder = hasConfiguredOperatingModes;

  const label = useMemo(() => {
    if (mode === "Remainder") return canShowRemainder ? "Remainder" : undefined;
    if (mode === "000000000000000000000000") return "Shutdown";
    return modes?.find((x) => x._id === mode)?.description;
  }, [modes, mode, canShowRemainder]);

  return (
    label && (
      <>
        <T.Label>Mode</T.Label>
        <T.Value>{label}</T.Value>
      </>
    )
  );
}

const minutesInDay = 24 * 60;

function SlopingTrendsTooltipStuff({ _id }: { _id: string }) {
  const slopeQuery = useSlopeQuery(_id);

  const data = slopeQuery.data;

  if (!data) return null;

  return (
    <>
      <T.Label>Length</T.Label>
      <T.Value>{data.anom} days</T.Value>
      <T.Label>Slope</T.Label>
      <T.Value>
        {(data.slope * minutesInDay).toFixed(3)}
        {!data.firstOccurrence && "(first occurrence)"}
        {typeof data.slopePercentChange === "number" && (
          <span className="ml-3">
            ({data.slopePercentChange}%
            <FaArrowUp
              className={cn(
                "inline size-3 ml-1",
                data.slopePercentChange < 0 && "rotate-180"
              )}
            />
            )
          </span>
        )}
      </T.Value>
      {data.firstOccurrence && (
        <>
          <T.Label>First Detected</T.Label>
          <T.Value>
            {DateTime.fromISO(data.firstOccurrence, { zone: "utc" }).toFormat(
              "LLL d, yyyy"
            )}
          </T.Value>
        </>
      )}
    </>
  );
}

export { GlobalTooltip };
