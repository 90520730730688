import { useEffect, useMemo, useState } from "react";
import { useDateState } from "../../../zustand/useDateState";
import { BsFillLightningChargeFill } from "react-icons/bs";
import {
  useAriaClustersQuery,
  useClusterDriftScoreQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { type clusterSchema } from "../../../lib/api-schema/cluster";
import getAriaColors from "../../common/aria-colors";
import { useShowClickedLine } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import {
  AriaSecondaryVariableViewNeedsProvider,
  AriaSecondaryVariableViewProvider,
} from "../../time-series/secondary-variable-view/aria-secondary-variable.view";
import { useTzAwareDateStateDomain } from "../../time-series/secondary-variable-view/chart-range/use-tz-aware-date-state-domain";
import { DynamicStatusSeries, StatusSeries } from "./status-series";
import { colorGen } from "../../../shared-ui/lib/getColors";
import Color from "color";
import { cn } from "../../../lib/utils";
import { Badge } from "../../../shared-ui/frontend/badge";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { ClusterScoreBadge } from "../cluster-score-badge";
import { VIEW_MODE, ViewModeSelectors } from "../../common/view-mode-selectors";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import { chartDimensionsConfig } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import useBaseUrl from "../../../zustand/useBaseUrl";

const DRIFT_LEVELS = getAriaColors();

export function ClusterCharts(props: { selectedClusters: string[] }) {
  const $ds = useDateState();
  const cdsQuery = useClusterDriftScoreQuery(
    $ds.axisRangeFrom.dateString,
    $ds.axisRangeTo.dateString
  );
  const clustersQuery = useAriaClustersQuery({
    at: $ds.axisRangeTo.local,
    filterType: "dynamic",
  });
  const [globalViewMode, setGlobalViewMode] = useState<VIEW_MODE>("chart");
  const [viewMode, setViewMode] = useState<Record<string, VIEW_MODE>>(
    Object.fromEntries((clustersQuery?.data || []).map((c) => [c._id, "chart"]))
  );
  const [globalNumCols, setGlobalNumCols] = useState<number>(3);
  const [numCols, setNumCols] = useState<Record<string, number>>(
    Object.fromEntries((clustersQuery?.data || []).map((c) => [c._id, 3]))
  );
  useEffect(() => {
    setNumCols(
      Object.fromEntries(
        (clustersQuery.data || []).map((c) => [
          c._id,
          c.variables.length > 9 ? 4 : c.variables.length <= 5 ? 2 : 3,
        ])
      )
    );
  }, [clustersQuery.data]);

  const clusters = useMemo(() => {
    return (clustersQuery.data || [])
      .filter((cluster) => props.selectedClusters.includes(cluster._id))
      .map((c) => {
        // attach by drift score
        const cdsData = cdsQuery.data || {};
        const cdsToday =
          cdsData[Object.keys(cdsData).sort().at(-1) || ""] || {};
        return {
          ...c,
          driftScore: cdsToday[c._id]?.maxLevel || 0,
        };
      })
      .sort((a, b) => {
        // sort by drift score then by name
        if (a.driftScore === b.driftScore) {
          return a.name.localeCompare(b.name);
        }
        return b.driftScore - a.driftScore;
      });
  }, [clustersQuery.data, cdsQuery.data, props.selectedClusters]);

  return (
    <div className="grow">
      {clusters.length === 0 && (
        <div className="text-center text-2xl mt-4 font-semibold text-xslate-11">
          No clusters to display
        </div>
      )}
      {clusters.map((cluster, idx) => {
        const cdsHeader =
          idx === 0 || clusters[idx - 1]?.driftScore !== cluster.driftScore;
        return (
          // id used by sidebar to scroll to
          <div key={cluster._id} id={`cluster-${cluster._id}`}>
            {cdsHeader && (
              <div
                className={cn(
                  "border-b border-xslate-7 flex flex-row items-center pb-1.5 mb-2",
                  idx > 0 && "pb-2 pr-2 mt-8"
                )}
              >
                <span className="text-xl tracking-tight text-xslate-12 font-semibold">
                  {
                    DRIFT_LEVELS.find((l) => l.value === cluster.driftScore)
                      ?.label
                  }{" "}
                  Clusters
                </span>
                <Badge className="select-none ml-2" variant="aria">
                  {
                    clusters.filter((c) => c.driftScore === cluster.driftScore)
                      .length
                  }{" "}
                  Clusters
                </Badge>
                {idx === 0 && (
                  <ViewModeSelectors
                    variant={"aria"}
                    className="ml-auto mr-1.5"
                    viewMode={globalViewMode}
                    withLabels={true}
                    setViewMode={(mode) => {
                      setGlobalViewMode(mode);
                      setViewMode(
                        Object.fromEntries(
                          (clustersQuery.data || []).map((c) => [c._id, mode])
                        )
                      );
                    }}
                    numCols={globalNumCols}
                    setNumCols={(n) => {
                      setGlobalNumCols(n);
                      setNumCols(
                        Object.fromEntries(
                          (clustersQuery.data || []).map((c) => [c._id, n])
                        )
                      );
                    }}
                  />
                )}
              </div>
            )}
            <ClusterCard
              cluster={cluster}
              viewMode={viewMode[cluster._id] || "chart"}
              setViewMode={(mode) => {
                setViewMode({ ...viewMode, [cluster._id]: mode });
              }}
              numCols={numCols[cluster._id] || 3}
              setNumCols={(n) => {
                setNumCols({ ...numCols, [cluster._id]: n });
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

function ClusterCard({
  cluster,
  viewMode,
  setViewMode,
  numCols,
  setNumCols,
}: {
  cluster: clusterSchema;
  viewMode: VIEW_MODE;
  setViewMode: (mode: VIEW_MODE) => void;
  numCols: number;
  setNumCols: (n: number) => void;
}) {
  const domain = useTzAwareDateStateDomain();
  const showLineClick = useShowClickedLine();
  const variableObjects = useVariablesArrayQuery()?.data || [];
  const toggleFs = useToggleFullscreen();
  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = useBaseUrl();

  const chartDims = chartDimensionsConfig(
    viewMode === "grid"
      ? {
          isGridView: true,
          numCols,
        }
      : { isGridView: false }
  );
  return (
    <div className="rounded-lg bg-white mb-3 border border-xslate-6">
      <div className="flex flex-row mb-1 p-1.5 items-start">
        <div className="inline-flex items-center">
          <span className="text-md tracking-tight">
            {cluster.type === "dynamic" ? (
              <BsFillLightningChargeFill className="h-4 w-4 mr-1 inline" />
            ) : null}
            {cluster.name}
          </span>
          <Badge className="select-none ml-1 h-min" variant="secondary">
            {cluster.variables.length} Tags
          </Badge>
          <ClusterScoreBadge score={cluster.score} />
        </div>
        <ViewModeSelectors
          variant={"aria"}
          className="ml-auto"
          viewMode={viewMode}
          setViewMode={setViewMode}
          numCols={numCols}
          setNumCols={setNumCols}
        />
        <Link
          to={`${baseUrl}/pb?z=${urlParams.get("z")}&d=${urlParams.get("d")}&mo=${urlParams.get("mo")}&y=${urlParams.get("y")}&dv=${cluster.variables.map((vid) => variableObjects.find((v) => v._id === vid)?.short_id).join("%2C")}`}
          target="_blank"
        >
          <ExternalLink className="size-4 text-xslate-12 my-2.5 mr-4 ml-3" />
        </Link>
      </div>
      {viewMode !== "chart" && (
        <div className="mr-12 ml-3 mb-1">
          <StatusSeries clusterId={cluster._id} domain={domain} />
        </div>
      )}
      <div
        className={`grid ${viewMode === "grid" ? `p-2 grid-cols-${numCols} gap-2` : "grid-cols-1 gap-2"}`}
      >
        {viewMode === "chart" && cluster.variables.length > 0 ? (
          <AriaSecondaryVariableViewProvider
            clusterId={cluster._id}
            initialVariables={cluster.variables as [string, ...string[]]}
            initialExpanded
          >
            <div className="mr-12 ml-3">
              <DynamicStatusSeries clusterId={cluster._id} />
            </div>
            <AriaSecondaryVariableViewNeedsProvider
              onLineClick={({ bvOrId, clientX, clientY, jotaiStore }) => {
                showLineClick({
                  clientX,
                  clientY,
                  jotaiStore,
                  bvOrId,
                });
              }}
              ableToGetTaller
              {...chartDims}
              padding={{
                left: 45,
              }}
            />
          </AriaSecondaryVariableViewProvider>
        ) : (
          cluster.variables.map((v, i) => {
            const color =
              colorGen.getN([], cluster.variables.length).at(i) || "#fff";
            const darkened = Color(color).darken(0.4).toString();
            return (
              <AriaSecondaryVariableViewProvider
                key={v}
                initialExpanded={false}
                clusterId={cluster._id}
                initialVariables={[v]}
              >
                <AriaSecondaryVariableViewNeedsProvider
                  className="border"
                  onLineClick={
                    toggleFs &&
                    (({ jotaiStore: s }) => {
                      toggleFs(s);
                    })
                  }
                  {...chartDims}
                  padding={{
                    left: 45,
                  }}
                  primaryVariableColor={[color, darkened]}
                  numTicks={4}
                  ableToGetTaller
                  onlyMinMaxYAxes={viewMode === "grid"}
                />
              </AriaSecondaryVariableViewProvider>
            );
          })
        )}
      </div>
    </div>
  );
}
