import { atom, useAtom, useAtomValue } from "jotai";
import { cn, iife } from "../../../lib/utils";
import {
  GenericGlobalSidebarWithGroups,
  type GroupButtonLabelComponent,
  SettingsDropdown,
} from "../../common/sidebar/generic-global-sidebar-with-groups";
import { DropdownMenuCheckboxItem } from "../../../shared-ui/frontend/dropdown-menu";
import {
  useAriaClustersQuery,
  useClusterDriftScoreQuery,
} from "../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import { useDateState } from "../../../zustand/useDateState";
import { Badge } from "../../ui/badge";
import * as R from "remeda";
import { memo } from "react";

enum Drift {
  No,
  Moderate,
  Major,
}

type SettingsOptions =
  | "Number of Major Drift Clusters"
  | "Number of Major and Moderate Drift Clusters"
  | "None";

const settingsOptions: { [K in SettingsOptions]: K } = {
  "Number of Major Drift Clusters": "Number of Major Drift Clusters",
  "Number of Major and Moderate Drift Clusters":
    "Number of Major and Moderate Drift Clusters",
  None: "None",
};

const filterAtom = atom<SettingsOptions>(
  settingsOptions["Number of Major Drift Clusters"]
);

const Label: GroupButtonLabelComponent = ({
  group,
  sidebarOpen,
  mySectionClosed,
  selectedGroup,
  className,
}) => {
  const filter = useAtomValue(filterAtom);
  const $ds = useDateState();
  const ariaClustersQuery = useAriaClustersQuery();
  const cdsQuery = useClusterDriftScoreQuery(
    $ds.axisRangeFrom.dateString,
    $ds.axisRangeTo.dateString
  );

  const cdsData = cdsQuery.data;
  const ariaClusters = ariaClustersQuery.data;

  const ariaClustersAssociatedWithThisGroup = useMemo(() => {
    const cids = ariaClusters
      ?.filter((c) => c.variables.some((v) => group.variables.includes(v)))
      .map((c) => c._id);

    return cids && new Set(cids);
  }, [ariaClusters, group]);

  const counts = useMemo(():
    | {
        major: number;
        moderate: number;
      }
    | undefined => {
    if (!ariaClustersAssociatedWithThisGroup) return undefined;
    if (!cdsData) return undefined;

    const today = Object.keys(cdsData).sort().at(-1);

    if (!today) return undefined;

    const out = cdsData[today];

    if (!out) return undefined;

    const inThisGroup = Object.entries(out).filter(([cid]) =>
      ariaClustersAssociatedWithThisGroup.has(cid)
    );

    const numMajor = R.sumBy(inThisGroup, (x) => {
      const isMajor = x[1].maxLevel === Drift.Major;
      return +isMajor; // 0 or 1
    });

    const numModerate = R.sumBy(inThisGroup, (x) => {
      const isMod = x[1].maxLevel === Drift.Moderate;
      return +isMod; // 0 or 1
    });

    return {
      major: numMajor,
      moderate: numModerate,
    };
  }, [cdsData, ariaClustersAssociatedWithThisGroup]);

  if (!counts || filter === settingsOptions.None) return undefined;

  const count = iife(() => {
    switch (filter) {
      case settingsOptions["Number of Major Drift Clusters"]:
        return counts.major || undefined;
      case settingsOptions["Number of Major and Moderate Drift Clusters"]:
        const total = counts.major + counts.moderate;
        return total || undefined;
      default:
        const _: never = filter;
        throw new Error();
    }
  });

  return (
    count && (
      <Badge
        key={filter}
        className={cn("ml-auto light", className)}
        variant={"outline"}
      >
        {count}
      </Badge>
    )
  );
};

export const GlobalAriaSidebar = memo(function () {
  const filter = useAtomValue(filterAtom);

  return (
    <GenericGlobalSidebarWithGroups labelComponent={Label}>
      {(open, children) => {
        return (
          <>
            <div
              className={cn("justify-end flex items-center", !open && "hidden")}
            >
              {filter !== "None" && (
                <span className="text-xs font-light italic max-w-max ml-auto select-none">
                  {filter}
                </span>
              )}
              <SettingsDropdownForAria />
            </div>

            {children}
          </>
        );
      }}
    </GenericGlobalSidebarWithGroups>
  );
});

function SettingsDropdownForAria() {
  const [selectedFilter, setSelectedFilter] = useAtom(filterAtom);

  return (
    <SettingsDropdown>
      {Object.keys(settingsOptions).map((x) => {
        return (
          <DropdownMenuCheckboxItem
            key={x}
            checked={selectedFilter === x}
            onCheckedChange={() => setSelectedFilter(x as SettingsOptions)}
          >
            {x}
          </DropdownMenuCheckboxItem>
        );
      })}
    </SettingsDropdown>
  );
}
