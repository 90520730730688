import { atom, useAtom, useAtomValue } from "jotai";
import {
  GenericGlobalSidebarWithGroups,
  type GroupButtonLabelComponent,
  SettingsDropdown,
} from "../../common/sidebar/generic-global-sidebar-with-groups";
import { DropdownMenuCheckboxItem } from "../../../shared-ui/frontend/dropdown-menu";
import { cn, iife } from "../../../lib/utils";
import { useTotalExceedanceCountsOnOlPage } from "./ol-details-charts";
import {
  defaultExcludedTypes,
  defaultQueryMode,
  useGetUseOlDetailsStore,
} from "./use-ol-details-store";
import { Badge } from "../../ui/badge";
import * as R from "remeda";
import { minutesToMilliseconds } from "date-fns";
import { useMemo } from "use-memo-one";
import { useDateState } from "../../../zustand/useDateState";
import { DateTime } from "luxon";

type SettingsOptions =
  | "Number of Exceedances"
  | "Number of Exceeded Tags"
  | "None";

const settingsOptions: { [K in SettingsOptions]: K } = {
  "Number of Exceeded Tags": "Number of Exceeded Tags",
  "Number of Exceedances": "Number of Exceedances",
  None: "None",
};

const filterAtom = atom<SettingsOptions>(
  settingsOptions["Number of Exceeded Tags"]
);

const Label: GroupButtonLabelComponent = ({
  group,
  mySectionClosed,
  selectedGroup: _,
  sidebarOpen,
  className,
}) => {
  const ds = useDateState();
  const end = ds.axisRangeTo.dateString;
  const sds = ds.selectedDateStart;

  const filter = useAtomValue(filterAtom);

  const useStore = useGetUseOlDetailsStore();

  // respect these filters
  const excludeLevels = useStore((s) => s.excludeLevels);

  const { countsByHighOrLow, data } = useTotalExceedanceCountsOnOlPage(
    group,
    excludeLevels,
    defaultExcludedTypes,
    defaultQueryMode,
    {
      enabled: sidebarOpen && !mySectionClosed,
      staleTime: minutesToMilliseconds(5),
      refetchOnMount: false,
    }
  );

  const numTags = useMemo(() => {
    if (!data) return undefined;

    /**
     * Still using fake dates on operating limits
     */
    const max = DateTime.fromISO(end, { zone: "utc" });
    const min = DateTime.fromISO(sds, { zone: "utc" });

    let count = 0;

    for (const x of data) {
      const d = DateTime.fromISO(x.date); // full ISO strings doesn't need a zone specified

      const include = d <= max && d >= min;

      if (!include) continue;

      count += x.variables;
    }

    return count;
  }, [data, end, sds]);

  if (filter === "None") return undefined;

  const numShow = iife(() => {
    switch (filter) {
      case settingsOptions["Number of Exceeded Tags"]:
        if (numTags === undefined) return undefined;
        return numTags || undefined;
      case settingsOptions["Number of Exceedances"]:
        return (
          (countsByHighOrLow &&
            R.sumBy(Object.values(countsByHighOrLow), (v) => v)) ||
          undefined
        );
      default:
        const _: never = filter;
        throw new Error();
    }
  });

  return (
    numShow && (
      <Badge
        key={filter}
        className={cn("ml-auto light select-none", className)}
        variant={"outline"}
      >
        {numShow}
      </Badge>
    )
  );
};

export function GlobalOperatingLimitsSidebar() {
  const filterOption = useAtomValue(filterAtom);

  return (
    <GenericGlobalSidebarWithGroups labelComponent={Label}>
      {(open, children) => (
        <>
          <div
            className={cn("justify-end flex items-center", !open && "hidden")}
          >
            {filterOption !== "None" && (
              <span className="text-xs font-light italic max-w-max ml-auto">
                {filterOption}
              </span>
            )}
            <SettingsDropdownForOL />
          </div>

          {children}
        </>
      )}
    </GenericGlobalSidebarWithGroups>
  );
}

function SettingsDropdownForOL() {
  const [selectedFilter, setSelectedFilter] = useAtom(filterAtom);

  return (
    <SettingsDropdown>
      {Object.keys(settingsOptions).map((x) => {
        return (
          <DropdownMenuCheckboxItem
            key={x}
            checked={selectedFilter === x}
            onCheckedChange={() => setSelectedFilter(x as SettingsOptions)}
          >
            {x}
          </DropdownMenuCheckboxItem>
        );
      })}
    </SettingsDropdown>
  );
}
