import { cn } from "../../lib/utils";
import { Badge } from "../../shared-ui/frontend/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shared-ui/frontend/tooltip";
import { PropsWithCn } from "../types/component.types";

export function ClusterScoreBadge({
  className,
  score,
}: PropsWithCn<{ score: number | null | undefined }>) {
  if (score === null || score === undefined) return null;
  const variant =
    score >= 95
      ? ({ color: "success", label: "Excellent" } as const)
      : score >= 90
        ? ({ color: "blue", label: "Very Good" } as const)
        : score >= 70
          ? ({ color: "amber", label: "Good" } as const)
          : score >= 40
            ? ({ color: "orange", label: "Fair" } as const)
            : ({ color: "danger", label: "Poor" } as const);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className={cn("whitespace-nowrap inline-flex flex-nowrap", className)}
        >
          <Badge
            variant={variant.color}
            className="select-none rounded-r-none border-r-0 ml-1"
          >
            {variant.label}
          </Badge>
          <Badge className="select-none rounded-l-none" variant={variant.color}>
            {score}
          </Badge>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>Long-Term Relationship Strength (ranges from 0 to 100)</p>
      </TooltipContent>
    </Tooltip>
  );
}
