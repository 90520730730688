import { useAtomValue } from "jotai";
import { useEffect, useId, useRef } from "react";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import * as d3 from "d3";
import getAriaColors from "../../common/aria-colors";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { useClusterDriftScoreQuery } from "../../../hooks/tanstack-query";

const HEIGHT = 10;
const WIDTH = 1000;
const DRIFT_LEVELS = getAriaColors();
type Props = {
  clusterId: string;
  domain: [number, number];
  zoomedDomain?: [number, number];
};

export function DynamicStatusSeries({ clusterId }: Omit<Props, "domain">) {
  const zoomedDomain = useAtomValue(useAtomValue(Atoms.drawnDomainAtomAtom));
  const initialDomain = useAtomValue(Atoms.getDomainAtom);
  return (
    <StatusSeries
      clusterId={clusterId}
      zoomedDomain={zoomedDomain}
      domain={initialDomain}
    />
  );
}

export function StatusSeries({ clusterId, domain, zoomedDomain }: Props) {
  const zone = useTimezone();
  const [start, end] = domain;
  const startDate = DateTime.fromMillis(start, { zone });
  const endDate = DateTime.fromMillis(end, { zone });
  const cdsQuery = useClusterDriftScoreQuery(
    startDate.toFormat("yyyy-MM-dd") as `${number}-${number}-${number}`,
    endDate.toFormat("yyyy-MM-dd") as `${number}-${number}-${number}`
  );
  const cdsData = cdsQuery.data || {};
  const data = Object.keys(cdsData)
    .sort()
    .map((day) => {
      const level = cdsData[day]![clusterId];
      return {
        day: day as `${number}-${number}-${number}`,
        level: level?.maxLevel || 0,
        shutdown: level?.isShutdown || false,
      };
    });
  const ref = useRef<SVGSVGElement>(null);
  const id = useId();

  useEffect(() => {
    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const x = d3
      .scaleTime()
      .domain((zoomedDomain || domain).map((d) => new Date(d)))
      .range([0, WIDTH]);
    const withTz = data.map((d) => ({
      ...d,
      day: DateTime.fromISO(d.day, { zone }),
    }));

    svg
      .selectAll("rect")
      .data(withTz)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.day.toJSDate()))
      .attr("y", 0)
      .attr("width", (d) => {
        const end = d.day.endOf("day");
        return x(end.toJSDate()) - x(d.day.toJSDate());
      })
      .attr("height", HEIGHT)
      .attr("fill", (d) =>
        d.shutdown
          ? `url(#${id}${d.level})`
          : DRIFT_LEVELS.find((l) => l.value === d.level)?.rgb || "#fff"
      );

    const defs = svg.append("defs");
    DRIFT_LEVELS.forEach((level, index) => {
      const color = level.rgb;
      const pattern = defs
        .append("pattern")
        .attr("id", `${id}${index}`)
        .attr("width", 8)
        .attr("height", 10)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("patternTransform", "rotate(45 50 50)");
      pattern
        .append("rect")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("fill", color);
      pattern
        .append("line")
        .attr("stroke", "#444444")
        .attr("stroke-width", "9px")
        .attr("y2", 10);
    });
  }, [data, domain, zone, id]);

  return <svg ref={ref} viewBox={`0 0 ${WIDTH} ${HEIGHT}`} />;
}
