import { useMemo } from "use-memo-one";
import {
  type BaseQueryOptions,
  useAnomalousVariablesQuery,
} from "../../../hooks/tanstack-query";
import { Group } from "../../../lib/api-schema/group";
import { useDateState } from "../../../zustand/useDateState";
import * as R from "remeda";

/**
 * Used for new DRI sidebar feature as of 2024.2
 */
export function useDriSidebarAnomalyCounts(
  group: Group,
  opts?: BaseQueryOptions
) {
  const ds = useDateState();
  const q = useAnomalousVariablesQuery(
    {
      varIds: group.variables,
    },
    ds.axisRangeTo.dateString,
    opts
  );

  const data = q.data;

  return useMemo(() => {
    if (!data) return undefined;

    const groupedByAnomLevel = R.groupBy(data, (x) => x.level);

    return groupedByAnomLevel;
  }, [data]);
}
