import { type MotionProps, motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { type PropsWithChildren } from "react";
import { globalGroupsSidebarOpenAtom } from "./generic-global-sidebar-with-groups";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { iife } from "../../../lib/utils";
import { cn, PropsWithCn } from "../../../shared-ui/frontend/cn";

export const localSidebarMotionVariants = {
  open: {
    width: "20dvw",
  },
  closed: {
    width: "0",
  },
} as const satisfies MotionProps["variants"];

export const localSidebarCollapseButtonMotionVariants = {
  globalOpenLocalOpen: {
    left: "40dvw",
  },
  globalOpenLocalClosed: {
    left: "20dvw",
  },
  globalClosedLocalClosed: {
    left: "",
  },
  globalClosedLocalOpen: {
    left: "20dvw",
  },
} as const satisfies MotionProps["variants"];

export function LocalSidebarContainer({
  children,
  open: localSidebarOpen,
  className,
}: PropsWithChildren<{ open: boolean }> & PropsWithCn) {
  return (
    <motion.div
      className={cn(
        "bg-white sticky top-[3rem] border-r border-zinc-300 overflow-y-auto min-h-[calc(100vh-3rem)] h-[calc(100vh-3rem)] mt-3 shrink-0",
        className
      )}
      animate={
        (localSidebarOpen
          ? "open"
          : "closed") satisfies keyof typeof localSidebarMotionVariants
      }
      variants={localSidebarMotionVariants}
    >
      {children}
    </motion.div>
  );
}

export function LocalSidebarCollapseButton({
  open: localSidebarOpen,
  onOpenToggle: toggleSidebar,
}: {
  open: boolean;
  onOpenToggle: () => void;
}) {
  const globalSidebarOpen = useAtomValue(globalGroupsSidebarOpenAtom);

  return (
    <motion.button
      animate={iife(
        (): keyof typeof localSidebarCollapseButtonMotionVariants => {
          if (localSidebarOpen) {
            return globalSidebarOpen
              ? "globalOpenLocalOpen"
              : "globalClosedLocalOpen";
          } else {
            return globalSidebarOpen
              ? "globalOpenLocalClosed"
              : "globalClosedLocalClosed";
          }
        }
      )}
      variants={localSidebarCollapseButtonMotionVariants}
      onClick={toggleSidebar}
      className={
        "fixed bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4 border-xindigo-7 border hover:border-xindigo-8 h-28 w-4 rounded-r-lg cursor-pointer top-[60%] -translate-y-[60%] flex items-center transition-all hover:scale-110 z-40"
      }
    >
      {localSidebarOpen ? (
        <IoChevronBackOutline className="size-4" />
      ) : (
        <IoChevronForwardOutline className="size-4" />
      )}
    </motion.button>
  );
}
