import {
  type BaseQueryOptions,
  useExceedanceGroupCountsQuery,
} from "../../../hooks/tanstack-query";
import {
  type ExcludeLevels,
  type ExcludeTypes,
  useGetUseOlDetailsStore,
} from "./use-ol-details-store";
import { useSelectedGroupOnOperatingLimitsPages } from "./group-select";
import { useDateState } from "../../../zustand/useDateState";
import BarChart from "../../charts/BarChart";
import {
  ALL_LIMIT_LEVELS_ORDERED,
  LEVEL_MAGNITUDES,
  type QueryMode,
} from "../constants";
import * as R from "remeda";
import moment from "moment";
import InputOld from "../../common/InputOld";
import type { PropsWithCn } from "../../types/component.types";
import { cn, iife } from "../../../lib/utils";
import { Group } from "../../../lib/api-schema/group";

/**
 * Extracted to hook because used elsewhere as well
 */
export function useTotalExceedanceCountsOnOlPage(
  group: Group | undefined,
  excludeLevels: ExcludeLevels,
  excludeTypes: ExcludeTypes,
  probabilityMode: QueryMode,
  opts?: BaseQueryOptions
) {
  const ds = useDateState();

  const axisRangeFrom = ds.axisRangeFrom.dateString;
  const axisRangeTo = ds.axisRangeTo.dateString;

  const exceedanceCountsQuery = useExceedanceGroupCountsQuery(
    {
      ...opts,
      enabled:
        // default enabled if not passed in but also group must be present
        (opts?.enabled ?? true) && !!group,
    },
    {
      excludeLevels,
      excludeTypes,
      groupId: group?._id ?? "query is disabled when no group is selected",
      mode: probabilityMode,
      start: axisRangeFrom,
      end: axisRangeTo,
    }
  );

  const data = exceedanceCountsQuery.data;

  const countsByHighOrLow = iife(() => {
    // calculate total count but only for the grey part of the chart

    if (!data) return undefined;

    const theGreyPartData = data.filter(({ date }) => {
      const valueIsInRange =
        moment.utc(date).isSameOrAfter(moment.utc(ds.selectedDateStart)) &&
        moment.utc(date).isSameOrBefore(moment.utc(ds.axisRangeTo.dateString));

      return valueIsInRange;
    });

    const relevantLevels = theGreyPartData.map((d) => d.levels);

    const tuples = R.mapToObj(ALL_LIMIT_LEVELS_ORDERED, (level) => {
      const countForThisLevel = R.sumBy(
        relevantLevels,
        (counts) => counts[level] ?? 0
      );
      return [level, countForThisLevel];
    });

    return tuples;
  });

  return { countsByHighOrLow, data };
}

export function OperatingLimitsOverviewCharts({ className }: PropsWithCn) {
  const useStore = useGetUseOlDetailsStore();
  const ds = useDateState();
  const excludeTypes = useStore((s) => s.excludeTypes);
  const probabilityMode = useStore((s) => s.queryMode);
  const excludeLevels = useStore((s) => s.excludeLevels);

  const getTotalCountLabel = () => {
    if (excludeTypes.high && excludeTypes.low) {
      throw new Error("both high and low are excluded");
    }

    if (excludeTypes.high) {
      return "Low Only";
    }

    if (excludeTypes.low) {
      return "High Only";
    }

    return "High & Low";
  };

  const { countsByHighOrLow, data } = useTotalExceedanceCountsOnOlPage(
    useSelectedGroupOnOperatingLimitsPages(),
    excludeLevels,
    excludeTypes,
    probabilityMode
  );

  return (
    <div className={cn("flex gap-6 px-5", className)}>
      <div className="relative min-h-[240px] w-[65%]">
        {/* Left-most chart */}
        {/* Stats */}
        <div className="absolute right-0 p-[0.4em] border-b border-l border-bordgrey flex justify-end rounded-bl-md">
          <div className="OperatingLimitsDetails__stat">
            <span className="OperatingLimitsDetails__stat__value">
              {countsByHighOrLow &&
                R.sumBy(Object.values(countsByHighOrLow), (v) => v)}
            </span>
            <span className="OperatingLimitsDetails__stat__label">Total </span>
            <span className="OperatingLimitsDetails__stat__label OperatingLimitsDetails__stat__label--drop">
              {getTotalCountLabel()}
            </span>
          </div>
          {Object.entries(LEVEL_MAGNITUDES)
            .map(([numAsStr, tuple]) => {
              return [
                numAsStr,
                R.sumBy(tuple, (level) => countsByHighOrLow?.[level] ?? 0),
              ] as const;
            })
            .filter(([, count]) => count > 0)
            .map(([numAsStr, count]) => {
              return (
                <div className="OperatingLimitsDetails__stat" key={numAsStr}>
                  <span className="OperatingLimitsDetails__stat__value">
                    {count}
                  </span>
                  <span className="OperatingLimitsDetails__stat__label">
                    Level {numAsStr}{" "}
                  </span>
                  <span className="OperatingLimitsDetails__stat__label OperatingLimitsDetails__stat__label--drop">
                    {getTotalCountLabel()}
                  </span>
                </div>
              );
            })}
          <div className="OperatingLimitsDetails__stats__info">
            <InputOld
              type="info"
              icon="info-circle"
              classes={{
                Input__label: "OperatingLimitsDetails__stats__info__label",
              }}
              label="Exceedance Summary for Analysis Period"
            />
          </div>
        </div>

        <BarChart
          classes={{
            BarChart:
              "rounded-md OperatingLimitsDetails__BarChart OperatingLimitsDetails__OverviewChart OperatingLimitsDetails__OverviewChart--left",
            BarChart__title: "OperatingLimitsDetails__OverviewChart__title",
          }}
          showTitle={true}
          chartTitle="Number of Exceedances"
          xAxisLabel="Date"
          yAxisLabel="Number of Exceedance"
          chartKeys={[...Array(5).keys()].map((num) => `Level ${num + 1}`)}
          selectedRange={[ds.selectedDateStart, ds.axisRangeTo.dateString]}
          data={(data ?? []).map(({ date, levels }) => {
            return {
              key: date,
              value: Object.entries(LEVEL_MAGNITUDES)
                .sort(([a], [b]) => parseInt(a) - parseInt(b)) // ascending 1,2, 3, 4, 5
                .map(([, tuple]) => {
                  const highAndLowSum = R.sumBy(
                    tuple,
                    (level) => levels[level] ?? 0
                  );
                  return highAndLowSum;
                }),
            };
          })}
          onclick={undefined}
          selectedIndex={undefined}
          range={undefined}
          unit={undefined}
          barColors={undefined}
          hiddenData={undefined}
        />
      </div>

      {/* Right-most chart */}
      <BarChart
        classes={{
          BarChart:
            "rounded-md OperatingLimitsDetails__BarChart OperatingLimitsDetails__OverviewChart flex-1",
          BarChart__title: "OperatingLimitsDetails__OverviewChart__title",
        }}
        showTitle={true}
        chartTitle={`Number of Variables`}
        xAxisLabel={"Date"}
        yAxisLabel={"Variable Count"}
        chartKeys={["Overview Count"]}
        selectedRange={[ds.selectedDateStart, ds.axisRangeTo.dateString]}
        data={
          data?.map(({ date, variables: numVars }) => {
            return {
              key: new Date(date),
              value: [numVars],
            };
          }) ?? []
        }
        onclick={undefined}
        selectedIndex={undefined}
        range={undefined}
        unit={undefined}
        barColors={undefined}
        hiddenData={undefined}
      />
    </div>
  );
}
