import React, { type PropsWithChildren } from "react";
import DeveloperView from "./DeveloperView";
import { DateStateCalendar } from "../common/date-state-calendar/date-state-calendar";
import { AxisRangeButtonGroupAndLabelToLeft } from "./axis-range-button-group";
/**
 * The second nav bar that interfaces with the global DateStateProvider. By interacting with this component,
 * all components in the DOM that are subscribed to useDateState can see and react to changes in the DateStateProvider
 */
export default function DateStateNav({
  leftmostChildren,
  children,
  allowRangeCalendar,
}: PropsWithChildren<{
  leftmostChildren?: React.ReactNode;
  allowRangeCalendar?: boolean;
}>) {
  return (
    <div
      className="p-2 bg-zinc-50 w-full md:sticky top-0"
      id="secondary-navbar"
      style={{ zIndex: 15 }}
    >
      <div className="w-full flex flex-col md:flex-row gap-2 items-center md:place-content-between">
        <div className="flex flex-col md:flex-row gap-2 items-center">
          {leftmostChildren}
          <DateStateCalendar allowRangeCalendar={allowRangeCalendar} />
          {children}
        </div>
        <div className="flex flex-col md:flex-row">
          {/* can only see this in development mode */}
          <DeveloperView />
          <AxisRangeButtonGroupAndLabelToLeft />
        </div>
      </div>
    </div>
  );
}
