import { z } from "zod";

export const clusterSchema = z.object({
  _id: z.string(),
  variables: z.array(z.string()),
  name: z.string(),
  description: z.string(),
  unit: z.string(),
  type: z.enum(["static", "dynamic"]),
  score: z.number().int().nullish(),
  aria_enabled: z.boolean(),
  start: z.string().datetime().nullish(),
  end: z.string().datetime().nullish(),
  updated_at: z.string().datetime().nullish(),
  created_at: z.string().datetime().nullish(),
  last_run: z.string().datetime().nullish(),
});

export type clusterSchema = z.infer<typeof clusterSchema>;
