import React, { memo, useState } from "react";
import getColors from "../common/colors";
import BarChart from "../charts/BarChart";
import _ from "lodash";
import { useDateState } from "../../zustand/useDateState";
import { withRgbString } from "../common/colors-typescript";
import { useAnomalySummariesForDay } from "../../hooks/tanstack-query";
import { useSelectedGroup } from "./use-selected-group";

const ANOM_LEVELS = withRgbString(getColors());
ANOM_LEVELS.sort((a, b) => b.value - a.value); // ascending order

export const DailyBreakdown = memo(function DailyBreakdown() {
  const selectedGroup = useSelectedGroup();

  const [excludedAnomLevels, setExcludedAnomLevels] = useState(
    ANOM_LEVELS.map(() => false)
  ); // parallel array starts as all false

  const $ds = useDateState();
  const anomSummary =
    useAnomalySummariesForDay(
      selectedGroup?.variables ?? [],
      $ds.axisRangeFrom.dateString,
      $ds.axisRangeTo.dateString,
      {
        enabled: !!selectedGroup,
      }
    ).data || [];

  const shownLevelCounts = (levels: number[]) => {
    const shownLevels = _.reverse(levels).filter(
      (l, i) => !excludedAnomLevels[i]
    );
    const hiddenLevel =
      levels.reduce((s, a) => s + a, 0) -
      shownLevels.reduce((s, a) => s + a, 0);
    _.reverse(shownLevels);
    shownLevels.push(hiddenLevel);
    return shownLevels;
  };

  const hiddenLevels = (levels: number[]) => {
    const hiddenLevels = _.reverse(levels).filter(
      (l, i) => excludedAnomLevels[i]
    );

    return hiddenLevels.reduce((s, a) => s + a, 0);
  };

  /**
   * Must add white at the end because the blocks at the top are white
   */
  const shownColorBars = (levels: typeof ANOM_LEVELS) => {
    return [
      ..._.reverse(
        levels.map((c) => c.rgb).filter((_, i) => !excludedAnomLevels[i])
      ),
      "#fff",
    ];
  };

  return (
    <div className="card border border-zinc-300 h-full rounded-md overflow-visible">
      <h2 className="card-title bg-base-100 py-1 pr-3 pl-2 flex flex-row justify-between rounded-t-md items-start">
        <span>Daily Breakdown</span>
        <div className="flex flex-row text-[1.2rem] mr-2 mt-1">
          {ANOM_LEVELS.map(({ value, color, rgb }, idx) => {
            return (
              <div
                className={`border-b-4`}
                style={{ borderColor: rgb }}
                key={rgb}
              >
                <input
                  id={`exclAnomLevel${value + 1}`}
                  type="checkbox"
                  className={`checkbox checkbox-sm mx-1 checkbox-${color} z-50 hover:border hover:border-neutral`}
                  checked={!excludedAnomLevels[idx]}
                  onChange={(e) =>
                    setExcludedAnomLevels((curr) =>
                      curr.map((b, i) => (i === idx ? !e.target.checked : b))
                    )
                  }
                />
                <span className="text-[0.9rem] relative bottom-1">
                  {value}&deg;
                </span>
              </div>
            );
          })}
        </div>
      </h2>
      <div className="bg-white h-full rounded-b-md">
        <BarChart
          classes={undefined}
          onclick={undefined}
          selectedIndex={undefined}
          showTitle={undefined}
          unit={undefined}
          withStripes
          chartTitle="Daily Breakdown"
          xAxisLabel={"Date"}
          yAxisLabel={"Number of variables"}
          selectedRange={[
            $ds.axisRangeTo.dateString,
            $ds.axisRangeTo.dateString,
          ]}
          chartKeys={["level1", "level2", "level3"]} // todo why is this needed?
          barColors={shownColorBars(ANOM_LEVELS)}
          // onclick={(m) => {
          //   document
          //     .querySelectorAll(".datapoint")
          //     .forEach((x) => x.setAttribute("filter", ""));
          //   document
          //     .querySelector(`[data-key='${m.valueOf()}']`)
          //     .setAttribute(
          //       "filter",
          //       "drop-shadow(2px 0px 0px black) drop-shadow(-2px 0px 0px black) drop-shadow(0px 2px 0px black) drop-shadow(0px -2px 0px black)"
          //     );
          //   // setDt(m);
          //   // setSelectedCharts(
          //   //   Object.values(
          //   //     anomSummary.filter(
          //   //       (s) =>
          //   //         moment(s.date).format("YYYY-MM-DD") ===
          //   //         m.format("YYYY-MM-DD")
          //   //     )[0].levelVariableIds
          //   //   ).flat()
          //   // );
          // }}
          data={anomSummary.map((s) => {
            return {
              key: s.date,
              value: shownLevelCounts(Object.values(s.levelCounts)),
              // stripes: s.shutdown,
              stripes: s.shutdownMilliseconds > 0,
            };
          })}
          // kinda hacky way of getting tooltip value and positioning to account for hidden levels
          hiddenData={anomSummary.map((s) => {
            return {
              key: s.date,
              value: hiddenLevels(Object.values(s.levelCounts)),
            };
          })}
          range={(() => {
            if (excludedAnomLevels.every((b) => !b)) {
              return undefined;
            }
            return [
              0,
              Math.max(
                ...anomSummary.map((s) => {
                  return shownLevelCounts(Object.values(s.levelCounts))
                    .slice(0, -1)
                    .reduce((s, a) => s + a, 0);
                })
              ),
            ];
          })()}
        />
      </div>
    </div>
  );
});
