import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { useGroupsQuery } from "../../../hooks/tanstack-query";
import { z } from "zod";
import { groupSchema } from "../../../lib/api-validators";
import { DaisySelect } from "../../common/Select/Select";
import { OVERALL_GROUP_NAME } from "../../../lib/api-schema/misc";

export function useSelectedGroupOnOperatingLimitsPages() {
  const { groupId } = useParams(); // this hooks is always use on a page where this must exist
  const gid = z.string().parse(groupId); // meaning this should throw if used on a different page
  const groupsQuery = useGroupsQuery();

  const nav = useNavigate();

  React.useEffect(() => {
    if (!groupsQuery.data) return; // don't check yet

    const groupIdIsValid = groupsQuery.data.some((g) => g._id === gid);
    if (!groupIdIsValid) nav("/404");
  }, [groupsQuery.data, gid, nav]);

  return groupsQuery.data?.find((g) => g._id === gid);
}

export function useSelectedGroupOnAriaPages() {
  const { groupId } = useParams();
  const groupsQuery = useGroupsQuery();

  if (!groupId) {
    // return overall group
    return (
      groupsQuery.data?.find((g) => g.name === OVERALL_GROUP_NAME) ??
      groupsQuery.data?.[0]
    );
  }

  const gid = z.string().parse(groupId); // meaning this should throw if used on a different page
  return groupsQuery.data?.find((g) => g._id === gid);
}

export function GroupSelect({
  value,
  onChange,
  valueFn,
}: {
  valueFn: (g: groupSchema) => string;
  onChange?: (value: string) => void;
} & Pick<React.ComponentProps<typeof DaisySelect>, "value">) {
  const groupsQuery = useGroupsQuery();
  const groupsData = groupsQuery.data;

  return (
    <DaisySelect
      className="max-w-[200px]"
      value={value}
      bordered
      onChange={(e) => onChange?.(e.target.value)}
      options={(groupsData ?? []).map((g) => {
        return {
          value: valueFn(g),
          label: g.name,
        };
      })}
    />
  );
}
