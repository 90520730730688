import { atom, useAtom, useAtomValue } from "jotai";
import { DropdownMenuCheckboxItem } from "../../shared-ui/frontend/dropdown-menu";
import {
  GenericGlobalSidebarWithGroups,
  GroupButtonLabelComponent,
  SettingsDropdown,
} from "../common/sidebar/generic-global-sidebar-with-groups";
import { cn, iife } from "../../lib/utils";
import { useDateState } from "../../zustand/useDateState";
import {
  BaseQueryOptions,
  useAnomalousVariablesQuery,
  useFreshAnomaliesQuery,
  useRisksQuery,
} from "../../hooks/tanstack-query";
import { useDriSidebarAnomalyCounts } from "./hooks/use-dri-sidebar-data";
import { minutesToMilliseconds } from "date-fns";
import { Badge } from "../ui/badge";
import { AnomalyLevelEnum } from "../../types/api/Anomaly";
import { ComponentProps, memo, useMemo } from "react";
import { Group } from "../../lib/api-schema/group";

type SettingsOptions =
  | "Number of 3° Tags"
  | "Number of Fresh 3° Tags"
  | "Number of 2° Tags"
  | "Number of Fresh 2° Tags"
  | "Group DRI value"
  | "None";

const settingsOptions: { [K in SettingsOptions]: K } = {
  "Number of 3° Tags": "Number of 3° Tags",
  "Number of Fresh 3° Tags": "Number of Fresh 3° Tags",
  "Number of 2° Tags": "Number of 2° Tags",
  "Number of Fresh 2° Tags": "Number of Fresh 2° Tags",
  "Group DRI value": "Group DRI value",
  None: "None",
};

const driGlobalSidebarFilterOptionAtom = atom<SettingsOptions>(
  settingsOptions["Number of 3° Tags"]
);

function useFreshAnomsCountForGroup(g: Group, opts?: BaseQueryOptions) {
  const ds = useDateState();

  const intermediate = useAnomalousVariablesQuery(
    {
      varIds: g.variables,
    },
    ds.axisRangeTo.dateString,
    {
      staleTime: minutesToMilliseconds(5),
      ...opts,
    }
  );

  const anomalousVariables = intermediate.data;

  const anomalousVariablesPartitioned = useMemo(() => {
    if (!anomalousVariables) return undefined;

    return {
      first: anomalousVariables
        .filter((x) => x.level === AnomalyLevelEnum["Low Risk"])
        .map((x) => x.variableId),
      second: anomalousVariables
        .filter((x) => x.level === AnomalyLevelEnum["Medium Risk"])
        .map((x) => x.variableId),
      third: anomalousVariables
        .filter((x) => x.level === AnomalyLevelEnum["High Risk"])
        .map((x) => x.variableId),
    };
  }, [anomalousVariables]);

  const freshAnomsQuery = useFreshAnomaliesQuery(
    {
      date: ds.axisRangeTo.dateString,
      deg1array: anomalousVariablesPartitioned?.first,
      deg2array: anomalousVariablesPartitioned?.second,
      deg3array: anomalousVariablesPartitioned?.third,
    },
    {
      keepPreviousData: true,
      ...opts,
      enabled: !!anomalousVariablesPartitioned && (opts?.enabled ?? true),
    }
  );

  const freshData = freshAnomsQuery.data;

  return freshData;
}

const GroupButtonLabel: GroupButtonLabelComponent = ({
  group: g,
  sidebarOpen,
  mySectionClosed: mySectionIsClosed,
  selectedGroup: _,
  className,
}) => {
  const filterOption = useAtomValue(driGlobalSidebarFilterOptionAtom);

  const groupedByLevels = useDriSidebarAnomalyCounts(g, {
    enabled:
      sidebarOpen && filterOption !== "Group DRI value" && !mySectionIsClosed,
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  });

  const ds = useDateState();

  const risksQuery = useRisksQuery({
    groupId: g._id,
    end: ds.axisRangeTo.dateString,
    start: ds.axisRangeFrom.dateString,
    opts: {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(3),
      enabled:
        sidebarOpen &&
        filterOption === settingsOptions["Group DRI value"] &&
        !mySectionIsClosed,
    },
  });

  const freshAnomsCount = useFreshAnomsCountForGroup(g, {
    enabled: sidebarOpen && !mySectionIsClosed,
    refetchOnMount: false,
  });

  const label = iife((): number | undefined => {
    if (mySectionIsClosed) return undefined;

    switch (filterOption) {
      case settingsOptions["Group DRI value"]:
        if (!risksQuery.data) return undefined;

        const dri = risksQuery.data.at(-1)?.index?.toFixed(2);

        if (dri === undefined) return undefined;

        return parseFloat(dri);
      case settingsOptions["Number of 2° Tags"]:
        if (!groupedByLevels) return undefined;

        const num =
          groupedByLevels[AnomalyLevelEnum["Medium Risk"]]?.length ?? 0;

        if (!num) return undefined;
        return num;
      case settingsOptions["Number of 3° Tags"]:
        if (!groupedByLevels) return undefined;
        const out = groupedByLevels[AnomalyLevelEnum["High Risk"]]?.length ?? 0;

        if (!out) return undefined;

        return out;
      case settingsOptions["None"]:
        return undefined;
      case "Number of Fresh 2° Tags":
        if (!freshAnomsCount) return undefined;
        const freshSecond = freshAnomsCount.freshSecond.length;

        if (freshSecond === 0) return undefined;
        return freshSecond;
      case "Number of Fresh 3° Tags":
        if (!freshAnomsCount) return undefined;
        const freshThird = freshAnomsCount.freshThird.length;

        if (freshThird === 0) return undefined;
        return freshThird;
      default:
        const _: never = filterOption;
        throw new Error();
    }
  });

  return (
    label !== undefined && (
      <Badge
        key={filterOption}
        className={cn("ml-auto light", className)}
        variant={iife((): ComponentProps<typeof Badge>["variant"] => {
          if (filterOption === "Group DRI value") {
            const level = Math.ceil(label);

            switch (level) {
              case AnomalyLevelEnum["High Risk"]:
                return "anomRed";
              case AnomalyLevelEnum["Medium Risk"]:
                return "anomOrange";
              case AnomalyLevelEnum["Low Risk"]:
                return "anomYellow";
              case AnomalyLevelEnum["No Risk"]:
                return "anomGreen";
              default:
                return "secondary";
            }
          } else return "outline";
        })}
      >
        {filterOption === "Group DRI value" ? label.toFixed(2) : label}
      </Badge>
    )
  );
};

export const GlobalDriSidebar = memo(function GlobalDriSidebar() {
  const filterOption = useAtomValue(driGlobalSidebarFilterOptionAtom);

  return (
    <GenericGlobalSidebarWithGroups labelComponent={GroupButtonLabel}>
      {(open, children) => {
        return (
          <>
            <div
              className={cn("justify-end flex items-center", !open && "hidden")}
            >
              {filterOption !== "None" && (
                <span className="text-xs font-light italic max-w-max ml-auto select-none">
                  {filterOption}
                </span>
              )}
              <SettingsDropdownForDri />
            </div>

            {children}
          </>
        );
      }}
    </GenericGlobalSidebarWithGroups>
  );
});

function SettingsDropdownForDri() {
  const [selectedFilter, setSelectedFilter] = useAtom(
    driGlobalSidebarFilterOptionAtom
  );

  return (
    <SettingsDropdown>
      {Object.keys(settingsOptions).map((x) => {
        return (
          <DropdownMenuCheckboxItem
            key={x}
            checked={selectedFilter === x}
            onCheckedChange={() => setSelectedFilter(x as SettingsOptions)}
          >
            {x}
          </DropdownMenuCheckboxItem>
        );
      })}
    </SettingsDropdown>
  );
}
