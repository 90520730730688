import { BsChevronRight, BsFillLightningChargeFill } from "react-icons/bs";
import {
  useAriaClustersQuery,
  useClusterDriftScoreQuery,
} from "../../../hooks/tanstack-query";
import { cn } from "../../../lib/utils";
import { useDateState } from "../../../zustand/useDateState";
import React, { useState, useEffect } from "react";
import { Collapse } from "@mantine/core";
import getAriaColors from "../../common/aria-colors";
import { ellipsify } from "../../utils/stylable";
import { Badge } from "../../../shared-ui/frontend/badge";

const DRIFT_LEVELS = getAriaColors();
const DRIFT_SCORES = DRIFT_LEVELS.reduce(
  (acc, { value, label }) => {
    acc[value] = label;
    return acc;
  },
  {} as Record<string, string>
);
const DRIFT_COLORS = DRIFT_LEVELS.reduce(
  (acc, { value, rgb }) => {
    acc[value] = rgb;
    return acc;
  },
  {} as Record<string, string>
);

export function ClusterSidebar({
  selectedClusters,
}: {
  selectedClusters: string[];
}) {
  const $ds = useDateState();
  const cdsQuery = useClusterDriftScoreQuery(
    // really we just want the CDS for today, but we use this query elsewhere so it should use cache
    $ds.axisRangeFrom.dateString,
    $ds.axisRangeTo.dateString
  );
  const cdsData = cdsQuery.data || {};

  const cdsToday = cdsData[Object.keys(cdsData).sort().at(-1) || ""] || {};
  const filtered = Object.keys(cdsToday).filter((k) =>
    selectedClusters.includes(k)
  );

  return (
    <div
      className="flex-none w-[24rem] min-w-[24rem] border-r border-zinc-300 overflow-visible bg-base-100 relative"
      style={{ minHeight: "calc(100vh - 400px)" }}
    >
      <div className="sticky top-12">
        {Object.entries(DRIFT_SCORES)
          .sort((a, b) => +b[0] - +a[0])
          .map(([key, value]) => (
            <SidebarGroup
              key={key}
              color={DRIFT_COLORS[key as keyof typeof DRIFT_COLORS] || "black"}
              value={value}
              count={
                filtered.filter((k) => cdsToday[k]?.maxLevel === +key).length
              }
              items={filtered.filter((k) => cdsToday![k]?.maxLevel === +key)}
            />
          ))}
      </div>
    </div>
  );
}

function SidebarGroup({
  color,
  value,
  count,
  items,
}: {
  color: string;
  value: string;
  count: number;
  items: string[];
}) {
  const $ds = useDateState();
  const at = $ds.axisRangeTo.local;
  const clustersQuery = useAriaClustersQuery({ at, enabled: true });
  const clusters = clustersQuery.data || [];
  const [isOpen, setIsOpen] = useState(count > 0);

  useEffect(() => {
    setIsOpen(count > 0);
  }, [count]);
  return (
    <div>
      <div
        className={cn(
          "tab-label flex items-center gap-2 p-2 pl-5 tracking-tight group cursor-pointer hover:font-medium hover:bg-xslate-3"
        )}
        style={{
          borderBottomWidth: "3px",
          borderColor: color,
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className={cn("transition-transform", isOpen && "rotate-90")}>
          <BsChevronRight />
        </div>
        <span className="group-hover:ml-1 transition-all">{value}</span>

        <Badge variant={"aria"}>{count.toString()}</Badge>
      </div>
      <Collapse in={isOpen}>
        <div className="tab-content">
          <div className="flex flex-col text-[13px] bg-white">
            {items.length === 0 && (
              <div className="text-center text-zinc-500 py-2">No clusters</div>
            )}
            {items
              .sort((a, b) => {
                const aCluster = clusters.find((c) => c._id === a);
                const bCluster = clusters.find((c) => c._id === b);
                if (!aCluster || !bCluster) return 0;
                return aCluster.name.localeCompare(bCluster.name);
              })
              .map((item) => {
                const cluster = clusters.find((c) => c._id === item);
                if (!cluster) return null;
                return (
                  <a
                    className="flex items-stretch max-w-full cursor-pointer"
                    key={item}
                    href={`#cluster-${item}`}
                  >
                    <div className="inline-flex px-2 border-zinc-300 border-b items-center gap-2 bg-white hover:bg-zinc-200 h-10 grow">
                      <span className="text-sm text-ellipsis whitespace-nowrap grow tracking-tight">
                        {cluster.type === "dynamic" ? (
                          <BsFillLightningChargeFill className="h-4 w-4 mr-1 inline" />
                        ) : null}
                        {ellipsify(cluster.name, 30)}
                      </span>
                      <span className="leading-[0.72rem] h-3.5 bg-zinc-200 rounded-full px-1 text-[0.7rem] text-zinc-500 border border-zinc-300">
                        {cluster.variables.length}
                      </span>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
