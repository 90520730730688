import { useMemo } from "react";
import { Group } from "../../../lib/api-schema/group";
import {
  BaseQueryOptions,
  useGroupsQuery,
  useSectionsQuery,
} from "../../../hooks/tanstack-query";
import { Sections } from "../../../lib/api-schema/sections";
import * as R from "remeda";
import { minLen1 } from "../../../shared-ui/lib/utils";

export type SectionWithGroups = {
  section: Sections["sections"][number];
  groups: [Group, ...Group[]];
};

export function useGroupsWithSections(opts?: BaseQueryOptions) {
  const groups = useGroupsQuery(opts).data;
  const sections = useSectionsQuery(opts).data;

  return useMemo(():
    | {
        sectionsWithGroups: SectionWithGroups[];
        remainingGroups: Group[];
      }
    | undefined => {
    if (sections === undefined || !groups)
      // still loading
      return undefined;

    if (sections === null) {
      return {
        sectionsWithGroups: [],
        remainingGroups: groups,
      };
    }

    const [partOfASection, notPartOfASection] = R.partition(groups, (group) => {
      return sections.sections.some((s) => s.groups.includes(group._id));
    });

    return {
      /**
       * The ordering of the sections are determined by the
       * lowest display_idx of the groups in that section.
       *
       * Sections by themselves have no ordering unless they
       * have groups in them.
       */
      sectionsWithGroups: sections.sections
        .map((x): SectionWithGroups | undefined => {
          const groupsPartOfThisSection = partOfASection
            .filter((group) => x.groups.includes(group._id))
            .sort((a, b) => a.display_id - b.display_id);

          if (!minLen1(groupsPartOfThisSection)) return undefined;

          return {
            section: x,
            groups: groupsPartOfThisSection,
          };
        })
        .filter((x) => x !== undefined)
        .sort((a, b) => a.groups[0].display_id - b.groups[0].display_id),
      remainingGroups: notPartOfASection.sort(
        (a, b) => a.display_id - b.display_id
      ),
    };
  }, [groups, sections]);
}
