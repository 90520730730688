/**
 * Starting to migrate DRI page to Typescript
 */

import { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  globalGroupsSidebarOpenAtom,
  SelectedGroupContextProvider,
  ToggleGlobalSidebarButton,
} from "../common/sidebar/generic-global-sidebar-with-groups";
import { GlobalDriSidebar } from "./global-dri-sidebar";
import { useSelectedGroup } from "./use-selected-group";
import { GroupSelect, useOnSelectGroupOnDri } from "./group-select";
import { useSearchParams } from "react-router-dom";
import DateStateNav from "../dateSelector/date-state-nav";
import { Provider, useAtomValue } from "jotai";
import { DRIChartsTopOfPage } from "./dri-charts-top-of-page";
import useDebouncedOnWindowResize from "../common/useDebouncedOnWindowResize";
import { DailyBreakdown } from "./daily-breakdown";
import { Sidebar } from "./sidebar/sidebar";
import { CorrelatedTagsDrawerStoreProvider } from "../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { CorrelatedTagsDrawer } from "../clusters/cluster-drawer/correlated-tags-drawer";
import { VariabilityDrawerStoreProvider } from "../variability-view/variability-drawer";
import { ClusterDrawerStoreProvider } from "../clusters/cluster-drawer/use-cluster-drawer-store";
import { FullscreenProvider } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import Nav from "../nav/Nav";

function GlobalSidebar() {
  const selectedGroup = useSelectedGroup();
  const onSelectGroup = useOnSelectGroupOnDri();
  const [, setSp] = useSearchParams();

  return (
    <SelectedGroupContextProvider
      selectedGroup={selectedGroup}
      onSelect={(g) => onSelectGroup?.(g._id, setSp)}
    >
      <GlobalDriSidebar />
    </SelectedGroupContextProvider>
  );
}

function SecondNavContent() {
  const globalSidebarOpen = useAtomValue(globalGroupsSidebarOpenAtom);

  return !globalSidebarOpen && <GroupSelect />;
}

const PETAL_RATIO = 4;

function TopCharts() {
  const selectedGroup = useSelectedGroup();
  const [petalChartDimensions, setPetalChartDimensions] = useState<
    [number, number]
  >([0, 0]);

  const petalChartRef = useRef<HTMLDivElement>(null);

  // set the dimensions of the petal chart after initial render
  useEffect(() => {
    const petalContainer = petalChartRef.current;
    petalContainer &&
      setPetalChartDimensions([
        petalContainer.clientWidth,
        petalContainer.clientWidth / PETAL_RATIO,
      ]);
  }, []);

  useDebouncedOnWindowResize(
    "resize",
    () => {
      const petalContainer = petalChartRef.current;
      petalContainer &&
        setPetalChartDimensions([
          petalContainer.clientWidth,
          petalContainer.clientWidth / PETAL_RATIO,
        ]);
    },
    125
  );

  return (
    <div className="flex gap-6 bg-bggrey pt-4 items-stretch px-5">
      <DRIChartsTopOfPage
        ref={petalChartRef}
        group={selectedGroup}
        petalChartDimensions={petalChartDimensions}
      />
      <div className="grow">
        <DailyBreakdown />
      </div>
    </div>
  );
}

export function DynamicRiskIndexWrapper({ children }: PropsWithChildren) {
  return (
    <>
      <Nav />
      <div
        id="interactible-container"
        className={
          "relative MIN_H_SCREEN animate-in slide-in-from-bottom-7 duration-300"
        }
      >
        <div className="flex">
          <Provider>
            <GlobalSidebar />
            <div className="grow">
              <DateStateNav
                leftmostChildren={
                  <>
                    <ToggleGlobalSidebarButton />
                    <SecondNavContent />
                  </>
                }
              />
              <TopCharts />
              <div className="flex w-full">
                <Sidebar />
                <div className="flex flex-col justify-between grow relative min-w-0">
                  <div className="px-8 pb-24 mt-4 min-w-0">
                    <VariabilityDrawerStoreProvider>
                      <ClusterDrawerStoreProvider>
                        <CorrelatedTagsDrawerStoreProvider>
                          <FullscreenProvider>
                            <CorrelatedTagsDrawer />
                            {children}
                          </FullscreenProvider>
                        </CorrelatedTagsDrawerStoreProvider>
                      </ClusterDrawerStoreProvider>
                    </VariabilityDrawerStoreProvider>
                  </div>
                </div>
              </div>
            </div>
          </Provider>
        </div>
      </div>
    </>
  );
}
