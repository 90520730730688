import { RANGES } from "../../constants/dateState";
import moment from "moment";
import { useDateState } from "../../zustand/useDateState";
import { useTimeParams } from "./custom-range-button/global-custom-range-button";
import { PropsWithCn } from "../types/component.types";
import { cn } from "../../lib/utils";
import { useState } from "react";
import { differenceInDays, startOfDay } from "date-fns";
import { DateTimeRange } from "./editable/types";
import { DateTime } from "luxon";
import { Button } from "../../shared-ui/frontend/button";
import EditableDateTimeRange from "./editable/EditableDateTimeRange";

export function AxisRangeButtonGroupAndLabelToLeft({ className }: PropsWithCn) {
  const $ds = useDateState();
  const isInCustomMode = $ds.isCustom;
  const customTimeWithDefaults = useTimeParams();
  const [editMode, setEditMode] = useState(false);

  const shouldShowTimes = isInCustomMode;

  const getTimeLabelsTuple = () => {
    if (!shouldShowTimes) {
      return undefined;
    }

    const first = moment(customTimeWithDefaults[0]).format("h:mm A");
    const second = moment(customTimeWithDefaults[1]).format("h:mm A");

    return [first, second] as const;
  };

  const getRangeLabelsTuple = () => {
    const first = moment($ds.axisRangeFrom.dateString);
    const second = moment($ds.axisRangeTo.dateString);

    const fmt = (d: moment.MomentInput) => moment(d).format("MMMM D, YYYY");

    return [fmt(first), fmt(second)] as const;
  };

  const getRangeLabel = () => {
    const rangeLabelTuple = getRangeLabelsTuple();
    const timeLabelTupleMaybe = getTimeLabelsTuple();

    if (!timeLabelTupleMaybe) return rangeLabelTuple.join(" — ");

    const [time1, time2] = timeLabelTupleMaybe;

    return `${rangeLabelTuple[0]} ${time1} - ${rangeLabelTuple[1]} ${time2}`;
  };

  const startDateAndTime = DateTime.fromJSDate($ds.axisRangeFrom.local, {
    zone: "local",
  })
    .set({
      hour: customTimeWithDefaults[0].getHours(),
      minute: customTimeWithDefaults[0].getMinutes(),
      second: 0,
      millisecond: 0,
    })
    .toJSDate();

  const endDateAndTime = DateTime.fromJSDate($ds.axisRangeTo.local, {
    zone: "local",
  })
    .set({
      hour: customTimeWithDefaults[1].getHours(),
      minute: customTimeWithDefaults[1].getMinutes(),
      second: 0,
      millisecond: 0,
    })
    .toJSDate();

  const onChangeHandler = (newRange: Partial<DateTimeRange>) => {
    const newStart = new Date(newRange.start ?? startDateAndTime);
    const newEnd = new Date(newRange.end ?? endDateAndTime);

    const diff =
      Math.abs(
        // I only want to consider the days, not the time, so reset to start of day
        differenceInDays(startOfDay(newEnd), startOfDay(newStart))
      ) + 1;

    $ds.multiSet({
      end: newEnd,
      time: {
        start: moment(newStart).format("HH:mm"),
        end: moment(newEnd).format("HH:mm"),
      },
      rangeIdx: RANGES.length,
      customNumDays: diff,
    });
  };

  return (
    <>
      {editMode ? (
        <EditableDateTimeRange
          className="h-7 mr-2 border border-xslate-7 rounded-md"
          start={startDateAndTime.getTime()}
          end={endDateAndTime.getTime()}
          options={{
            showTime: true,
            fontSize: "base",
          }}
          onChangeHandler={onChangeHandler}
          close={() => setEditMode(false)}
          /**
           * When the component loses focus,
           * close it. This is optional and only
           * used in this case. Notice how other
           * <EditableDateTimeRange /> don't
           * need it depending on how it's used.
           *
           * Very important
           */
          onBlur={() => setEditMode(false)}
        />
      ) : (
        <Button
          variant={"outline"}
          size={"xs"}
          className={cn("md:ml-auto mr-2", className)}
          onClick={() => setEditMode(true)}
        >
          {getRangeLabel()}
        </Button>
      )}
      <div className="inline-flex">
        {RANGES.map((d, idx) => {
          const active = $ds.axisRangeIndex === idx;
          return (
            <Button
              className={cn(
                "not-last:rounded-r-none not-first:rounded-l-none",
                !active &&
                  "bg-white hover:bg-xindigo-4 hover:text-xindigo-11 hover:border-xindigo-7"
              )}
              variant={active ? "default" : "outline"}
              size={"xs"}
              key={d.label}
              onClick={() => $ds.onAxisRangeSelect(idx)}
            >
              {d.label}
            </Button>
          );
        })}
      </div>
    </>
  );
}
