import { FaArrowRight } from "react-icons/fa";
import { PLANT_OVERVIEW_URL } from "./constants";
import { LinkWithQuery } from "./LinkWithQuery2";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import { useGetLinkWithQueryPropsForRealtime } from "./use-get-link-with-query-props-for-realtime";
import { Badge } from "../ui/badge";
import { cn } from "../../lib/utils";
import useAllowedUnits from "../common/hooks/useAllowedUnits";
import { useParams, useLocation } from "react-router-dom";
import { routerPaths } from "../../constants/routerPaths";

export function UnitSelectDropdown() {
  const currentUnit = useCurrentUnitObject(); // can be undefined on the POV page
  const unitsICanSee = useAllowedUnits();

  const { unit } = useParams();
  const loc = useLocation();

  const pagePath = loc.pathname.replace(`/${unit}`, "");

  const getLinkWithQueryProps = useGetLinkWithQueryPropsForRealtime();

  const getRedirect = (u: (typeof unitsICanSee)[number]) => {
    /**
     * Some of the older pages were not written in such a way that
     * the effects handle getting updated data when the unit changes,
     * so we will redirect them to /ov instead to avoid this issue.
     * Until a later rewrite/refactor.
     */

    if (pagePath === `/${routerPaths.notifications}`) {
      return `${u.url}/ov`;
    } else if (
      pagePath.startsWith(`/${routerPaths.operatingfitness}/details`)
    ) {
      return `${u.url}/${routerPaths.operatingfitness}`;
    } else if (pagePath.startsWith(`/${routerPaths.aria}/details`)) {
      // take out group
      return `${u.url}/${routerPaths.aria}/details`;
    } else if (pagePath.startsWith(`/${routerPaths.control}`)) {
      return `${u.url}/control/tag`;
    } else {
      return `${u.url}${pagePath}`;
    }
  };

  return (
    <ul
      tabIndex={0}
      className="dropdown-content menu shadow-xl bg-base-100 rounded-md min-w-[300px] max-h-[60vh] overflow-y-scroll"
    >
      {unitsICanSee.map((unit) => {
        const activeUnit = currentUnit?.name === unit.name;
        const linkProps = getLinkWithQueryProps(unit.isRealTime);

        return (
          <li
            key={unit.name}
            className={cn(
              activeUnit &&
                "border-l border-4 border-r-0 border-y-0 border-xindigo-9"
            )}
          >
            <LinkWithQuery
              className="group"
              to={getRedirect(unit)}
              pick={{
                ...linkProps.pick,
                g: currentUnit?.name === unit.name,
                v: currentUnit?.name === unit.name,
                dv: currentUnit?.name === unit.name,
              }}
              set={linkProps.set}
            >
              <div className="flex items-center gap-2 w-full">
                <Badge
                  variant={activeUnit ? "primary" : "secondary"}
                  className="text-[11px]"
                >
                  {unit.shortName.length > 11
                    ? `${unit.shortName.slice(0, 8)}...`
                    : unit.shortName}
                </Badge>

                <div>{unit.displayName}</div>
                <div className="ml-auto invisible group-hover:visible">
                  <FaArrowRight />
                </div>
              </div>
            </LinkWithQuery>
          </li>
        );
      })}
      <li>
        <LinkWithQuery
          to={PLANT_OVERVIEW_URL}
          className={"flex justify-center"}
          pick={{ d: true, mo: true, y: true, z: true, cd: true }}
        >
          <div>All units</div>
        </LinkWithQuery>
      </li>
    </ul>
  );
}
