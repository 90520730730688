import React from "react";
import { ellipsify } from "../../utils/stylable";
import {
  useAnomalousVariablesQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { cn } from "../../../lib/utils";
import { useGetUseDisplayedChartsStores } from "../use-displayed-charts-store";
import { createStore, useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minLen1 } from "../../../shared-ui/lib/utils";
import { useMemo } from "use-memo-one";
import { useDateState } from "../../../zustand/useDateState";
import { anomalySchema } from "../../../lib/api-validators";
import { BsClock } from "react-icons/bs";
import { formatTime } from "../../dri/utils/utils";
import { useTagsFilter } from "../../tags/use-tags-filter";
import { useTagsFilterStoreContext } from "../../tags/use-tags-filter-store";

export function ProfileBookCurrentTagsList() {
  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();
  const charts = useDisplayedChartsStore((s) => s.charts);
  const ds = useDateState();
  const query = useAnomalousVariablesQuery(
    {
      varIds: charts?.map(
        (x) =>
          x.store.get(Atoms.onlyVariableTrendLinesVariableIdsAtom)[0] as string
      ),
    },
    ds.axisRangeTo.dateString,
    {
      enabled: !!charts,
    }
  );
  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomalyTimeFilter = tagsFilterStore((s) => s.anomalyTimeFilter);
  const canShowVariable = useTagsFilter(tagFilters, anomalyTimeFilter);

  return (
    charts && (
      <ul className="animate-in slide-in-from-bottom-5 divide-y divide-xslate-5">
        {charts
          ?.filter((x) =>
            canShowVariable(
              x.store.get(
                Atoms.onlyVariableTrendLinesVariableIdsAtom
              )[0] as string
            )
          )
          .map((x) => (
            <ListItem store={x.store} key={x.id} anomalyTimes={query.data} />
          ))}
      </ul>
    )
  );
}

function ListItem({
  store,
  anomalyTimes,
}: {
  store: ReturnType<typeof createStore>;
  anomalyTimes: anomalySchema[] | undefined;
}) {
  const variables = useVariablesArrayQuery().data;

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const variableIds = useAtomValue(
    Atoms.onlyVariableTrendLinesVariableIdsAtom,
    {
      store,
    }
  );

  const variableObjs = useMemo(() => {
    if (!variables) return undefined;

    return variableIds.map((x) => {
      const out = variables?.find((y) => y._id === x);
      if (!out) throw new Error("not found");
      return out;
    });
  }, [variables, variableIds]);

  if (!variableObjs || !minLen1(variableObjs)) return null;

  const [first, ...rest] = variableObjs;

  const anomTime =
    anomalyTimes?.find((a) => a.variableId === first._id)
      ?.anomalyMilliseconds || 0;

  return (
    <button
      type="button"
      onMouseDown={() => {
        const s = useDisplayedChartsStore.getState();
        const currCharts = s.charts ?? [];
        const currStore = currCharts.find((x) => x.store === store);
        if (!currStore) throw new Error("impossible");
        s.replaceCharts([
          currStore, // move to top
          ...currCharts.filter((x) => x !== currStore),
        ]);
      }}
      className={cn(
        "cursor-pointer lowercase w-full px-4 py-1 min-h-[2.5rem] flex flex-col justify-between text-[0.8rem] hover:bg-zinc-100 transition-all active:scale-95"
      )}
    >
      <div className="flex justify-between w-full">
        <div className="break-all text-indigo-600">
          {first.trimmedName || " "}
        </div>
        {anomTime > 0 && (
          <div className="text-xslate-11">
            <BsClock className="size-3 inline-flex mr-1" />
            {formatTime(anomTime / 1000)}
          </div>
        )}
      </div>
      {first.description.trim() && (
        <span className="break-all text-xslate-11">
          {ellipsify(first.description || " ", 300)}
        </span>
      )}
      {rest.map((x) => {
        return (
          <span className="font-light text-xs ml-2" key={x._id}>
            - {x.trimmedName}
          </span>
        );
      })}
    </button>
  );
}
